// HomeSection.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faSquareWhatsapp } from '@fortawesome/free-brands-svg-icons';
import logo from '../assets/rg-logo-final.png';
import '../styles/HomeSection.css';

const HomeSection = () => {
  return (
    <section id="home" className="bg-img">
      <div className="heading-container">
        <span>
          <h1>Rudrajit Ghosh, Advocate</h1>
        </span>
        <p>Civil, Commercial, Insolvency and White Collar Crimes Litigation<br>
        </br>Alternative Dispute Resolution<br>
        </br>Dispute Resolution Advisory</p>
        <span>
          <h2>Delhi • Kolkata</h2>
        </span>
      </div>
      <div className="corner top-left">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <div className="corner top-right">
        <span className="icon-text">
          <FontAwesomeIcon icon={faEnvelope} className="icon-e" />
          <a href="mailto:rudrajit@rgchambers.com">rudrajit@rgchambers.com</a>
        </span>
        <br />
        <span className="icon-text">
          <FontAwesomeIcon icon={faSquareWhatsapp} className="icon-w" />
          <a href="tel:+918276064890">+91 8276064890</a>
        </span>
      </div>
    </section>
  );
};

export default HomeSection;
