// DisclaimerModal.js
import React from 'react';
import logo from '../assets/rg-logo-final.png';
import '../styles/DisclaimerModal.css';

const DisclaimerModal = ({ onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="corner top-left"><img src={logo} alt="Logo" className="logo" /></div>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <h2>Disclaimer</h2>
        </div>
        <div className="modal-body">
          <p>Bar Council of India prohibits advertisement or solicitation by advocates in any form or manner. By accessing this website (rgchambers.com), you acknowledge and confirm that you are seeking information relating to law chambers of Rudrajit Ghosh of your own accord and that there has been no solicitation, advertisement or inducement by the law chambers of Rudrajit Ghosh or its associates, agents, nominees or assigns, directly or indirectly.</p> 
          <p>It is to be specifically understood by you that the website is not intended for solicitation or advertising; and that the website is for information purposes only. No information provided on this website is set out as, or ought to be construed as, legal advice. Similarly, accessing any information through this website, including unilateral communication on any contact number or email-id or address mentioned on the website, would not create any lawyer-client relationship.</p> 
          <p>Law chambers of Rudrajit Ghosh its associates, agents, nominees or assigns or any other person associated with it shall not be liable for consequences of direct or indirect reliance on or reference to the material/information provided on this website.</p>
          <p>The contents of this website are the intellectual property of Rudrajit Ghosh.</p>
        </div>
        <div className="accept-button" onClick={onClose}>I Accept
        </div>
      </div>
    </div>
  );
}

export default DisclaimerModal;

