// Importing necessary modules and components from React, Bootstrap, and local files
import React, { useState } from 'react';
import HomeSection from './components/HomeSection'; // Importing the HomeSection component
import DisclaimerModal from './components/DisclaimerModal'; // Importing the DisclaimerModal component
import 'bootstrap/dist/css/bootstrap.min.css'; // Importing Bootstrap CSS for styling

/**
 * Main App component.
 * 
 * This is the root component of the application. It manages the state for displaying
 * the disclaimer modal and renders the HomeSection component.
 * 
 * @returns {JSX.Element} The rendered App component.
 */
function App() {
  // useState hook to manage the state of showDisclaimer, initially set to true
  const [showDisclaimer, setShowDisclaimer] = useState(true);

  /**
   * Handles closing of the disclaimer modal.
   * 
   * This function sets the showDisclaimer state to false, which hides the modal.
   */
  const handleCloseDisclaimer = () => {
    setShowDisclaimer(false);
  };

  return (
    <div>
      {/* Conditional rendering of DisclaimerModal based on showDisclaimer state */}
      {showDisclaimer ? (
        <DisclaimerModal onClose={handleCloseDisclaimer} />
      ) : (
      <HomeSection />
      )}
    </div>
  );
}

export default App;
